export enum PAGE_URL {
  EVENT = 'event',
  HOME = 'home',
  EMBASSIES = 'embassies',
  PROFILE = 'profile',
  USER = 'user',
  ADMIN = 'admin',
  LOGIN = 'login',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset-password',
  LEGAL_MENTION = 'mentions-legales',
  PRIVACY_POLICY = 'privacy-policy',
  ACTIVATE = 'activate',
}
